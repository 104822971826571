import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Footer from './Footer';
import { db } from './firebase'; // Ensure your firebase config is correctly set up
import { collection, addDoc } from 'firebase/firestore';
import ThankYouPopUp from './ThankYouPopUp';
import { useNavigate } from 'react-router-dom';
import ProjectBG from './ProjectBG';


function StartProject() {
    const [formData, setFormData] = useState({
        name: '',
        companyName: '',
        email: '',
        contactNumber: '',
        goal: '',
        budgetRange: '',
        productDetails: '',
        countryCode: '+1', // Default country code
        country: '', // Field for country input
    });

    const [responseMessage, setResponseMessage] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [filteredCountries, setFilteredCountries] = useState([]);
    const [isTyping, setIsTyping] = useState(false); // Tracks if user is typing in country field

    // Comprehensive country code mapping
    const countryCodes = {
        'United States': '+1', 'US': '+1',
        'India': '+91', 'IN': '+91',
        'Canada': '+1', 'CA': '+1',
        'Australia': '+61', 'AU': '+61',
        'United Kingdom': '+44', 'UK': '+44',
        'France': '+33', 'FR': '+33',
        'Germany': '+49', 'DE': '+49',
        'China': '+86', 'CN': '+86',
        'Japan': '+81', 'JP': '+81',
        'Russia': '+7', 'RU': '+7',
        'Brazil': '+55', 'BR': '+55',
        'Mexico': '+52', 'MX': '+52',
        'Italy': '+39', 'IT': '+39',
        'South Korea': '+82', 'KR': '+82',
        'South Africa': '+27', 'ZA': '+27',
        'Spain': '+34', 'ES': '+34',
        'Netherlands': '+31', 'NL': '+31',
        'Belgium': '+32', 'BE': '+32',
        'Switzerland': '+41', 'CH': '+41',
        'Sweden': '+46', 'SE': '+46',
        'Norway': '+47', 'NO': '+47',
        'Denmark': '+45', 'DK': '+45',
        'Poland': '+48', 'PL': '+48',
        'Turkey': '+90', 'TR': '+90',
        'Greece': '+30', 'GR': '+30',
        'Portugal': '+351', 'PT': '+351',
        'Austria': '+43', 'AT': '+43',
        'Finland': '+358', 'FI': '+358',
        'Ireland': '+353', 'IE': '+353',
        'New Zealand': '+64', 'NZ': '+64',
        'Israel': '+972', 'IL': '+972',
        'Saudi Arabia': '+966', 'SA': '+966',
        'United Arab Emirates': '+971', 'AE': '+971',
        'Singapore': '+65', 'SG': '+65',
        'Malaysia': '+60', 'MY': '+60',
        'Philippines': '+63', 'PH': '+63',
        'Thailand': '+66', 'TH': '+66',
        'Vietnam': '+84', 'VN': '+84',
        'Argentina': '+54', 'AR': '+54',
        'Chile': '+56', 'CL': '+56',
        'Colombia': '+57', 'CO': '+57',
        'Venezuela': '+58', 'VE': '+58',
        'Pakistan': '+92', 'PK': '+92',
        'Bangladesh': '+880', 'BD': '+880',
        'Egypt': '+20', 'EG': '+20',
        'Morocco': '+212', 'MA': '+212',
        'Nigeria': '+234', 'NG': '+234',
        'Kenya': '+254', 'KE': '+254',
        'Ghana': '+233', 'GH': '+233',
        'Ethiopia': '+251', 'ET': '+251',
        'Ukraine': '+380', 'UA': '+380',
        'Hungary': '+36', 'HU': '+36',
        'Romania': '+40', 'RO': '+40',
        'Czech Republic': '+420', 'CZ': '+420',
        'Slovakia': '+421', 'SK': '+421',
        'Bulgaria': '+359', 'BG': '+359',
        'Serbia': '+381', 'RS': '+381',
        'Croatia': '+385', 'HR': '+385',
        'Slovenia': '+386', 'SI': '+386',
        'Lithuania': '+370', 'LT': '+370',
        'Latvia': '+371', 'LV': '+371',
        'Estonia': '+372', 'EE': '+372',
        // Add more countries as needed
    };

    // Convert countryCodes object to an array for easy filtering
    const countryNames = Object.keys(countryCodes);
    const navigate = useNavigate();
    const [showAnimation, setShowAnimation] = useState(true); // Controls animation visibility

    useEffect(() => {
        if (showAnimation) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        const timer = setTimeout(() => setShowAnimation(false), 2500); // 5 seconds animation

        return () => {
            clearTimeout(timer);
            document.body.style.overflow = 'auto';
        };
    }, [showAnimation])

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'country') {
            const userInput = value.trim().toLowerCase();

            // Filter country options dynamically
            const filtered = countryNames.filter(country =>
                country.toLowerCase().startsWith(userInput)
            );
            setFilteredCountries(filtered);
            setIsTyping(true); // Show dropdown while typing

            // Check if there's an exact match for country code
            const countryCode = countryCodes[userInput] || formData.countryCode;
            setFormData({ ...formData, country: value, countryCode });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const handleCountrySelect = (country) => {
        const countryCode = countryCodes[country];
        setFormData({ ...formData, country, countryCode });
        setFilteredCountries([]); // Hide the dropdown after selection
        setIsTyping(false); // Stop typing mode
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await addDoc(collection(db, 'projects'), {
                name: formData.name,
                companyName: formData.companyName,
                email: formData.email,
                contactNumber: `${formData.countryCode}${formData.contactNumber}`,
                goal: formData.goal,
                budgetRange: formData.budgetRange,
                productDetails: formData.productDetails,
                timestamp: new Date(),
            });

            setResponseMessage('Form submitted successfully!');
            setIsSubmitted(true);

            setFormData({
                name: '',
                companyName: '',
                email: '',
                contactNumber: '',
                goal: '',
                budgetRange: '',
                productDetails: '',
                countryCode: '+1',
                country: '',
            });
        } catch (error) {
            console.error('Error submitting form: ', error);
            setResponseMessage('There was an error submitting the form.');
        }
    };

    return (
        <>
            {/* Animation Section */}
            {showAnimation && (
                <div
                    className="fixed inset-0 z-50 flex items-center justify-center bg-black"
                    style={{ overflow: 'hidden' }}
                >
                    <img
                        src="/images/nexuzsend2.gif" // Replace with your PNG image path
                        alt="Loading..."
                        className="max-w-full max-h-full"
                    />                
                    </div>
            )}

            {!showAnimation && (

                <div className='bg-black relative'

                >
                    <ProjectBG />
                    <Navbar />
                    <div className="h-auto text-white flex flex-col items-center justify-center px-4 relative">
                        <div className="w-full ml-5">
                            <h1 className="text-6xl mt-10 sm:mt-16 lg:text-8xl md:mb-6 text-white mr-auto ">
                                Let's build a
                                <span className="text-cyan-400 overflow-hidden whitespace-nowrap block w-fit pb-3 lg:text-7xl text-4xl">
                                    Project Together.
                                </span>
                            </h1>
                            <p className="text-lg mx-auto md:text-2xl font-extralight">
                                Fill in your details to kickstart a great partnership with Nexuzforge, your product experience partner.
                            </p>
                        </div>

                        <form className="md:w-2/3 pt-8 px-8 rounded-lg mt-3 mb-3" onSubmit={handleSubmit}>
                            <div className="md:flex justify-center h-auto w-full">
                                <div className="justify-center h-auto w-full relative">
                                    {/* Name Field */}
                                    <div className="mb-4">
                                        <label className="block text-sm mb-2" htmlFor="name">
                                            Hi! My name is
                                        </label>
                                        <input
                                            className="md:w-3/4 p-2 px-5 bg-white/10 text-white rounded-full  border-white"
                                            id="name"
                                            type="text"
                                            name="name"
                                            placeholder="Enter your name*"
                                            value={formData.name}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    {/* Company Name Field */}
                                    <div className="mb-4">
                                        <label className="block text-sm mb-2" htmlFor="companyName">
                                            and I work with
                                        </label>
                                        <input
                                            className="md:w-3/4 p-2 px-5 bg-white/10 text-white rounded-full border-teal-400"
                                            id="companyName"
                                            type="text"
                                            name="companyName"
                                            placeholder="Enter your company name*"
                                            value={formData.companyName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    {/* Goal Field */}
                                    <div className="mb-4">
                                        <label className="block text-sm mb-2" htmlFor="goal">
                                            I'm looking for a partner to help me with
                                        </label>
                                        <input
                                            className="md:w-3/4 p-2 bg-white/10 text-white rounded-full px-5 border-teal-400"
                                            id="goal"
                                            type="text"
                                            name="goal"
                                            placeholder="Type your goal here*"
                                            value={formData.goal}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    {/* Budget Range Field */}
                                    <div className="mb-4">
                                        <label className="block text-sm mb-2" htmlFor="budgetRange">
                                            I'm hoping to stay around a budget range of
                                        </label>
                                        <select
                                            style={{
                                                appearance: 'none',
                                                WebkitAppearance: 'none',
                                                MozAppearance: 'none',
                                            }}
                                            className="md:w-3/4 p-2 bg-white/10 text-white rounded-full px-5 select-dropdown"
                                            id="budgetRange"
                                            name="budgetRange"
                                            value={formData.budgetRange}
                                            onChange={handleChange}
                                            required
                                        >
                                            <option value="" disabled >
                                                Select Pricing*
                                            </option>
                                            <option value="$1000 - $5000">$1000 - $5000</option>
                                            <option value="$5000 - $10000">$5000 - $10000</option>
                                            <option value="Above $10000">Above $10000</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="justify-center h-auto w-full relative">
                                    {/* Email Field */}
                                    <div className="mb-4">
                                        <label className="block text-sm mb-2" htmlFor="email">
                                            You can reach me at
                                        </label>
                                        <input
                                            className="w-full p-2 bg-white/10 text-white rounded-full px-5 border-teal-400"
                                            id="email"
                                            type="email"
                                            name="email"
                                            placeholder="name@example.com"
                                            value={formData.email}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>
                                    {/* Country Field with Dropdown */}
                                    <div className="mb-4 relative">
                                        <label className="block text-sm mb-2" htmlFor="country">
                                            Country
                                        </label>
                                        <input
                                            className="md:w-3/4 p-2 bg-white/10 text-white rounded-full px-5 border-teal-400"
                                            id="country"
                                            type="text"
                                            name="country"
                                            placeholder="Enter your country"
                                            value={formData.country}
                                            onChange={handleChange}
                                            onFocus={() => setIsTyping(true)}
                                            onBlur={() => setIsTyping(false)}
                                            required
                                        />
                                        {isTyping && filteredCountries.length > 0 && (
                                            <ul className="absolute z-10 bg-cyan-400 rounded-l-3xl text-white font-bold shadow-lg mt-1 max-h-40 overflow-y-auto">
                                                {filteredCountries.map((country) => (
                                                    <li
                                                        key={country}
                                                        className="p-2 px-3 cursor-pointer hover:bg-cyan-400"
                                                        onMouseDown={() => handleCountrySelect(country)}
                                                    >
                                                        {country}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </div>

                                    {/* Contact Number Field */}
                                    <div className="mb-4">
                                        <label className="block text-sm mb-2" htmlFor="contactNumber">
                                            Contact Number
                                        </label>
                                        <div className="flex items-center">
                                            <input
                                                className="w-[12%] p-2 bg-white/10 text-white rounded-l-full border-teal-400 text-center "
                                                id="countryCode"
                                                type="text"
                                                name="countryCode"
                                                value={formData.countryCode}
                                                readOnly
                                            />
                                            <input
                                                className="w-[70%] p-2 bg-white/10 text-white rounded-r-full px-5 border-teal-400"
                                                id="contactNumber"
                                                type="tel"
                                                name="contactNumber"
                                                placeholder="(901) 456-789"
                                                value={formData.contactNumber}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>

                                    {/* Product Details Field */}
                                    <div className="mb-6">
                                        <label className="block text-sm mb-2" htmlFor="productDetails">
                                            To start the conversation, I'm sharing more:
                                        </label>
                                        <textarea
                                            className="w-full p-2 bg-white/10 text-white rounded-3xl px-5 border-teal-400"
                                            id="productDetails"
                                            name="productDetails"
                                            placeholder="Type in your product details..."
                                            rows="4"
                                            value={formData.productDetails}
                                            onChange={handleChange}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            {/* Submit Button */}
                            <div className="flex gap-3">
                                <button
                                    className="bg-white text-black text-xs md:text-base py-3 px-5 rounded-full hover:bg-cyan-400 flex justify-center my-4 items-center md:translate-y-0 mx-auto"
                                    onClick={() => navigate('/privacy')} // Directly using navigate here
                                >
                                    <div>Privacy Policy</div>
                                    <div className="w-[20px] h-[20px] bg-black rounded-full ml-2"></div>
                                </button>

                                <button
                                    className="bg-white text-black py-3 px-5 rounded-full hover:bg-cyan-400 flex justify-center items-center md:my-auto my-4 md:translate-y-0 mx-auto"
                                    type="submit"
                                >
                                    <div>Submit</div>
                                    <div className="w-[20px] h-[20px] bg-black rounded-full ml-4"></div>
                                </button>
                            </div>
                        </form>
                        {/* Optional: Display response message */}
                        {responseMessage}
                    </div>

                    <Footer />
                    {/* Render the ThankYouPopup conditionally */}
                    {isSubmitted && <ThankYouPopUp />}
                </div>
            )}
        </>
    );
}

export default StartProject;
