import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import LandingAnimation from './LandingAnimation';
import HeroSection from './HeroSection';
import AboutSection from './AboutSection';
import TeamSection from './TeamSection';
import ProjectsSection from './ProjectsSection';
import ClientReviews from './ClientReviews';
import BentoBoxes from './BentoBoxes';
import Footer from './Footer';

const LandingPage = () => {
  const location = useLocation();
  const [showAnimation, setShowAnimation] = useState(true);

  useEffect(() => {
    // Handle navigation to specific sections
    if (location.state?.scrollTo) {
      const section = document.getElementById(location.state.scrollTo);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    const animationDuration = 2600; // Animation display duration

    if (showAnimation) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto'; // Reset scrolling
    }

    const timer = setTimeout(() => {
      setShowAnimation(false);
    }, animationDuration);

    return () => {
      clearTimeout(timer);
      document.body.style.overflow = 'auto'; // Cleanup on unmount
    };
  }, [showAnimation]);

  return (
    <div>
      {/* Animation Section */}
      {showAnimation && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black"
          style={{ overflow: 'hidden' }}
        >
          <LandingAnimation />
        </div>
      )}

      {/* Main Sections with Sticky Scroll */}
      {!showAnimation && (
        <div>
          {/* Hero Section */}
          <section
            id="hero"
            style={{
              backgroundColor: 'black',
              scrollSnapAlign: 'start',
            }}
          >
            <HeroSection />
          </section>

          {/* About Section */}
          <section
            id="what-we-do"
            style={{
              backgroundColor: 'black',
              scrollSnapAlign: 'start',
            }}
          >
            <AboutSection />
          </section>

          {/* Team Section */}

          {/* Projects Section */}
          <section
            id="projects"
            style={{
              backgroundColor: 'black',
              scrollSnapAlign: 'start',
            }}
          >
            <ProjectsSection />
          </section>
          <section
            id="team"
            style={{
              backgroundColor: 'black',
              scrollSnapAlign: 'start',
            }}
          >
            <TeamSection />
          </section>



          {/* Reviews Section */}
          <section
            id="reviews"
            style={{
              backgroundColor: 'black',
              scrollSnapAlign: 'start',
            }}
          >
            <ClientReviews />
          </section>
          <Footer />
        </div>
      )}
    </div>
  );
};

export default LandingPage;
